
document.addEventListener('DOMContentLoaded', () => {

  const values = obj => Object.values(obj)
  const head = arr => arr[0]
  const tail = arr => arr.slice(1)
  const entries = fn => obj => Object.keys( obj ).map( k => fn([k,obj[k]]))
  const arrayEntries = fn => arr => arr.map( ([k,v]) => fn(k,v))
  const pipe = (...fns) => input => fns.reduce( (acc,item) => item(acc), input )
  const mergeEntries = arr => arr.reduce((acc,[k,v]) => (acc[k] = v, acc) , {})
  const split = val => str => str.split(val)
  const joinList = joinStr => arr => arr.join(joinStr)
  const trace = message => ( console.log( message ), message )
  const getRandomListIndex = arr => Math.floor( arr.length * Math.random() )
  const moveRandomToListHead = arr => Identity.of(getRandomListIndex(arr))
    .map( randomIndex => [
      arr[randomIndex],
      ...arr.filter( (_,currentIndex) => currentIndex !== randomIndex )
    ]).join()
  const shuffle = arr => (arr.length ? Just.of(arr) : Nothing)
    .map( moveRandomToListHead )
    .map( arr => [head(arr), ...shuffle(tail(arr)) ] )
    .joinOrElse( () => [] )
  const isNully = a => a === undefined || a === null || ( typeof a === 'number' && isNaN( a ) )
  const tryCatch = failCallback => testCallback => {
    try {
      return testCallback()
    } catch ( e ) {
      return failCallback( e )
    }
  }
  const mapList = fn => arr => arr.map(fn)
  // Ad-hoc Monad helpers
  
  const setInnerHtml = el => value => el.innerHTML = value
  
  class Identity {
    constructor( value ) {
      this.$value = value
    }
    join() {
      return this.$value
    }
    map( fn ) {
      return Identity.of( fn( this.$value ) )
    }
    flatMap( fn ) {
      return this.map( fn ).join()
    }
    static of( value ) {
      return new Identity( value )
    }
  }
  const Nothing = {
    map: () => Nothing,
    join: () => Nothing,
    flatMap: () => Nothing,
    joinOrElse: (elseFn) => elseFn()
  }
  // Beware Just is instance of Identity
  class Just extends Identity {    
    joinOrElse(fn) {
      return this.join()
    }
    map( fn ) {
      return Just.of( fn( this.$value ) )
    }
    static of( value ) {
      return new Just( value )
    }
  }
  
  class Maybe extends Just {
    constructor( value ) {
      super()
      return isNully( value ) ? Nothing : Just.of( value )
    }
    static of( value ) {
      return new Maybe( value )
    }
  }
  const unWrapMaybe = nothingCallback => maybe =>  maybe === Nothing ? nothingCallback() : maybe.join()
  
  
  // safeHead: [<T>] -> Maybe(<T>)
  const safeHead = arr => ( Array.isArray(arr) && typeof arr[0] !== 'undefined') ? Just.of(arr[0]) : Nothing
  const safeProp = key => obj => ( typeof obj === 'object' && typeof obj[key] !== 'undefined') ? Just.of(obj[key]) : Nothing
  const prop = key => obj => obj[key]
  
  const setAttributeValue = trace
  const setAttributeValues = obj => [...document.querySelectorAll('[data-attributes]')]
    .map(
      el => {
        const maybeAttrVals = Maybe.of(el)
          .flatMap(safeProp('dataset'))
            // obj -> Maybe.of( obj )
          
          .map( values )
            // obj -> str[] 
          .flatMap( safeHead )
            // str[] -> Maybe.of( str )
          .map( split(';') )
            // str -> str[] 
          .map( arr => arr.map(split('=')))
          
            // str[] -> [ str[] ]
          .map( arrayEntries( (k,v) => [k, obj[v]] ) )
            // [ str[] ] -> [ str[] ]
          .map( mergeEntries )
          
            // [ str[] ] => {}
        const attrVals = unWrapMaybe( () => {
          console.error('Error processing data-self of this element:',el)
          return ({})
        } )(maybeAttrVals)
  
        // side effects
        Object.entries(attrVals).forEach( ([attr,val]) => el.setAttribute(attr,val) )
      } 
    )
  
  const setContentValues = obj => [...document.querySelectorAll('[data-content]')]
    .map( el => {
      const innerHTMLValue = Maybe.of(el)
        .flatMap(safeProp('dataset'))
        .map( values )
        // obj -> str[] 
        .flatMap( safeHead )
        .flatMap( i => isNully(obj[i]) ? Nothing : Just.of(obj[i]))
        .joinOrElse( () => (console.error('dataset not found'),''))
      
      // side-effects
      el.innerHTML = innerHTMLValue
    })
  
  const newLineWrap = tagName => className => lines => lines
    .map( line => `<${tagName}${ className ? ' class="'+className+'"' : '' }>${line}</${tagName}>`)
  const breakNewLines = copy => copy.replace(/<p>[ ]*[\n\r]/gm,'<p>&nbsp;')
  const parseEmailsIntoLinks = text => text.replace(/([^@\n\r]*\@[^@ \n\r]*\.[^@. \r\n]*)/g,'<a href="mailto:$1">$1</a>')
  const newLineWrapText = ({tagName,className = ''}) => str => newLineWrap(tagName)()(str.split("\n")).join('\n')
  const noData = defaultData => message => () => (console.error(message),defaultData)
  const noDataES = noData('')





  console.log('loaded')
  setAttributeValues({
    pdfMenu: `https://jos-data.hemaka.com/media/menus/${ data.menus[0].id }/menu_web_file/${ data.menus[0].pdf }`,
    mainBackgroundStyle: Maybe.of( data.cover_pictures )
      .map( moveRandomToListHead )
      .flatMap( safeHead )
      .flatMap( safeProp('image') )
      .map( str => `background-image: url(https://jos-data.hemaka.com/media/cover_pictures_cover_picture_image/${str})` )
      .joinOrElse( noData('background-color: black;')('Could not load background cover image') )
    })
  const unWrapAboutHtmlMaybe = unWrapMaybe(() => {
    console.error('Error reading loading aboutHtml')
    return ''
  })
  setContentValues({ 
    aboutHtml: Maybe.of(data.about)
      // .flatMap( arr => Just.of( arr.filter( i => i.id === 2) ) || Nothing )
        // obj[] -> obj[]
      .flatMap( safeHead )
        // obj[] -> obj
      .flatMap( safeProp('copy') )
        // obj -> str
      .map( parseEmailsIntoLinks )
        // str -> str
      .map( newLineWrapText({tagName: 'p'}) )
        // str -> str
      // .map( breakNewLines )
        // str -> str
      .joinOrElse( noDataES('About Html not loaded.')),
        // str | Nothing -> str
    galleryImages: Maybe.of(data.gallery_pictures)
      .map( mapList( i => `<img src="https://jos-data.hemaka.com/media/gallery_pictures_gallery_picture_image/${i.image}" />`))
      .map( joinList('\n'))
      .joinOrElse( noDataES('Gallery images not loaded.')),
    notice: Maybe.of(data.notice)
      .flatMap( safeHead )
      .flatMap( safeProp('copy') )
      .map( newLineWrapText({tagName: 'p'}) )
      .map( breakNewLines )
      .map(trace)
      .joinOrElse( noDataES('Notice data not loaded.') ),
    mobileNotice: Maybe.of( data.notice )
      .flatMap( safeHead )
      .flatMap( safeProp('mobile'))
      .map( newLineWrapText({tagName: 'p'}) )

      .joinOrElse( noDataES('Mobile notice data not loaded.') )
  })
})


// const pieceTogetherMail = (el) => {
//   const domain = el.dataset.domain
//   const address = el.dataset.address
//   const complete = `${address}@${domain}`
//   el.addEventListener('click',(event) => {
//     window.location = `mailto:${complete}`
//   })
//   if(!el.innerHTML.trim()) {
//     el.innerHTML = complete
//   }
// }

// document.addEventListener('DOMContentLoaded',(event) => {
//   pieceTogetherMail(document.querySelector('mail'))
// })






// // console.log('r')





// // })